import { FormattedMessage } from 'react-intl';

// assets
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
const icons = {
  AssuredWorkloadIcon
};
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const testBanks = {
  id: 'test-banks',
  type: 'group',
  children: [
    {
      id: 'test-banks',
      title: <FormattedMessage id="Test Banks" />,
      type: 'item',
      url: '/test-banks',
      icon: icons.AssuredWorkloadIcon
      ,
      breadcrumbs: false,
    },
  ],
};

export default testBanks;
