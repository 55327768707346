import axios from "axios";
import { getUserData } from "common/getUserData";
const BASE_URL =
process.env.REACT_APP_EDOC_API_URL || "";
export default function getAxiosInstance(link, method, payload, headers) {
  let userData = getUserData();
  let requestObj = {
    method: method,
    url: `${BASE_URL}/${link}`,
    data: payload,
    headers: {
      Authorization: `Bearer ${userData?.accessToken}`,
    },
  };
return axios(requestObj)
    .then((res) => {
      return res; // Return response in case of success
    })
    .catch((err) => {
      // Handle errors properly and reject the promise
      if (err.response) {
        return Promise.reject(err.response); // Reject with error response
      } else {
        return Promise.reject(err); // Reject with the whole error if no response
      }
    });
}
