import React, { useEffect, useState } from 'react';
import ReportCard from './report-card';

import ApiList from './transaction-list';
import {
  Box,
  Grid,
  Stack,
  Typography,
  Autocomplete,
  TextField,
} from '@mui/material';
import getAxiosInstance from 'api';


const SettlementAccount = () => {
  const [appName, setAppName] = useState([]);
  const [selectedApp, setSelectedApp] = useState('');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('DESC');
  const [orderBy, setOrderBy] = useState('');
  const [search, setSearch] = useState('');
  const [isFilter, setIsFilter] = useState(true);
  const [limit, setLimit] = useState(100);
  const [selectedLogType, setSelectedLogType] = useState();

  const userData = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    const payload = {
      page: page + 1,
      limit,
      sortBy: orderBy,
      sortDirection: order,
      search: search,
      filter: {
        isActive: isFilter,
      },
    };
    // if (userData.userRole === 5) {
    //   getAxiosInstance(`adminOrchestrator/app/list`, 'post', payload, {}).then(
    //     (res) => {
    //       if (res?.data?.codes === 200) {
    //         let appNameList =
    //           res?.data?.data?.items?.length &&
    //           res.data.data.items.map((item) => ({
    //             value: item.appName,
    //             label: item.appDisplayName || item.appName,
    //           }));
    //         setAppName(appNameList);

    //         // Set the first app as selected if it's not already set
    //         if (!selectedApp && appNameList.length > 0) {
    //           setSelectedApp(appNameList[0].value);
    //         }
    //       }
    //     }
    //   );
    // }
  }, []);

  useEffect(() => {
    // if (appName.length > 0 && !selectedApp) {
    setSelectedApp(appName[0]);
    // }
  }, [appName]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={2}
      >
        <Typography variant="h3">Payout History</Typography>
       
      </Stack>

      <ReportCard appName={selectedApp}  />   
      <ApiList appName={selectedApp} />
    </>
  );
};

export default SettlementAccount;
