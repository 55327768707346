import React from "react";

const NgCurrencySymbol = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.2632 10.773H14.4211V9.21839H16.2632C16.4586 9.21839 16.646 9.1365 16.7842 8.99073C16.9224 8.84496 17 8.64725 17 8.44109C17 8.23494 16.9224 8.03723 16.7842 7.89146C16.646 7.74569 16.4586 7.66379 16.2632 7.66379H14.4211V3.7773C14.4211 3.57115 14.3434 3.37344 14.2052 3.22767C14.0671 3.08189 13.8796 3 13.6842 3C13.4888 3 13.3014 3.08189 13.1632 3.22767C13.025 3.37344 12.9474 3.57115 12.9474 3.7773V7.66379H9.87474L8.40105 3.95608C8.26568 3.63184 8.02937 3.36611 7.73128 3.2029C7.43319 3.03968 7.09119 2.98878 6.76193 3.05861C6.43266 3.12844 6.13589 3.31482 5.92075 3.58688C5.70561 3.85894 5.58502 4.20036 5.57895 4.5546V7.66379H3.73684C3.54142 7.66379 3.354 7.74569 3.21582 7.89146C3.07763 8.03723 3 8.23494 3 8.44109C3 8.64725 3.07763 8.84496 3.21582 8.99073C3.354 9.1365 3.54142 9.21839 3.73684 9.21839H5.57895V10.773H3.73684C3.54142 10.773 3.354 10.8549 3.21582 11.0007C3.07763 11.1464 3 11.3441 3 11.5503C3 11.7564 3.07763 11.9542 3.21582 12.0999C3.354 12.2457 3.54142 12.3276 3.73684 12.3276H5.57895V16.2141C5.57895 16.4202 5.65658 16.6179 5.79476 16.7637C5.93295 16.9095 6.12037 16.9914 6.31579 16.9914C6.51121 16.9914 6.69863 16.9095 6.83682 16.7637C6.975 16.6179 7.05263 16.4202 7.05263 16.2141V12.3276H10.1253L11.5989 16.0353C11.7101 16.3165 11.8972 16.5571 12.137 16.7271C12.3767 16.8971 12.6585 16.989 12.9474 16.9914C13.0453 17.0029 13.1442 17.0029 13.2421 16.9914C13.58 16.9187 13.883 16.7233 14.0984 16.4393C14.3138 16.1552 14.428 15.8005 14.4211 15.4368V12.3276H16.2632C16.4586 12.3276 16.646 12.2457 16.7842 12.0999C16.9224 11.9542 17 11.7564 17 11.5503C17 11.3441 16.9224 11.1464 16.7842 11.0007C16.646 10.8549 16.4586 10.773 16.2632 10.773ZM12.9474 9.21839V10.773H11.1053L10.4937 9.21839H12.9474ZM7.05263 4.5546L8.28316 7.66379H7.05263V4.5546ZM7.05263 10.773V9.21839H8.89474L9.50632 10.773H7.05263ZM12.9474 15.4368L11.7168 12.3276H12.9474V15.4368Z"
          fill="#A3A3A3"
        />
      </svg>
    </>
  );
};

export default NgCurrencySymbol;
