import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import UpDownArrow from '../../../assets/images/svg/up-down-arrow.svg';
import CheckIcon from '../../../assets/images/svg/check.svg';
import FailedIcon from '../../../assets/images/svg/failed.svg';
import BlockIcon from '../../../assets/images/svg/block.svg';
import TotalRevenue from '../../../assets/images/svg/total.svg';
import getAxiosInstance from 'api';

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary[200],
  borderRadius: '10px',
  width: '52px',
  height: '52px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ReportCard = ({ appName, logType }) => {
  const [consentCount, setConsentCount] = useState();

  const userData = JSON.parse(localStorage.getItem('userData'));
  const modifyName = (value) => {
    switch (value) {
      case 'mandate':
        return 'Direct Debit';
      case 'bvn':
        return 'BVN';
      case 'consent':
        return 'Consent';
      default:
        return value;
    }
  };
  const fetchConsent = () => {
    // getAxiosInstance(
    //   `adminOrchestrator/app/${
    //     appName?.value
    //   }/request-count?journeyType=${modifyName(logType)}`,
    //   'get',
    //   {}
    // ).then((res) => {
    //   if (res?.data?.codes === 200) {
    //     setConsentCount(res?.data?.data);
    //   }
    // });
  };

  useEffect(() => {
    if (appName) {
      fetchConsent();
    }
  }, [appName, logType]);

  return (
    <>
      <>
        <Grid container spacing={3}>
          <Grid item md={3}>
            <MainCard>
              <Stack direction="column" spacing={3}>
                <IconWrapper>
                  <Box component={'img'} src={UpDownArrow} />
                </IconWrapper>
                <Stack spacing={1}>
                  <Typography variant="h3">
                    0{' '}
                  </Typography>
                  <Typography variant="body1">Total Transaction</Typography>
                </Stack>
              </Stack>
            </MainCard>
          </Grid>
          <Grid item md={3}>
            <MainCard>
              <Stack direction="column" spacing={3}>
                <IconWrapper>
                  <Box component={'img'} src={CheckIcon} />
                </IconWrapper>
                <Stack spacing={1}>
                  <Typography variant="h3">
                    {' '}
                    0{' '}
                  </Typography>
                  <Typography variant="body1">Successful Transaction</Typography>
                </Stack>
              </Stack>
            </MainCard>
          </Grid>
          <Grid item md={3}>
            <MainCard>
              <Stack direction="column" spacing={3}>
                <IconWrapper>
                  <Box component={'img'} src={FailedIcon} />
                </IconWrapper>
                <Stack spacing={1}>
                  <Typography variant="h3">
                    {' '}
                    0{' '}
                  </Typography>
                  <Typography variant="body1">Failed Transaction</Typography>
                </Stack>
              </Stack>
            </MainCard>
          </Grid>
          <Grid item md={3}>
            <MainCard>
              <Stack direction="column" spacing={3}>
                <IconWrapper>
                  <Box component={'img'} src={TotalRevenue} />
                </IconWrapper>
                <Stack spacing={1}>
                  <Typography variant="h3">
                    {' '}
                    0{' '}
                  </Typography>
                  <Typography variant="body1">Total Revenue</Typography>
                </Stack>
              </Stack>
            </MainCard>
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default ReportCard;
