// third-party
import { FormattedMessage } from 'react-intl';

// assets
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
const icons = {
  AssessmentOutlinedIcon,
};
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const sampleResponse = {
  id: 'sample-bank-response',
  type: 'group',
  children: [
    {
      id: 'sample-response',
      title: <FormattedMessage id="sample-bank-response" />,
      type: 'item',
      url: '/sample-bank-response',
      icon: icons.AssessmentOutlinedIcon,
      breadcrumbs: false,
    },
  ],
};

export default sampleResponse;
