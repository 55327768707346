// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconDashboard, IconDeviceAnalytics } from "@tabler/icons";

// constant
const icons = {
  IconDashboard,
  IconDeviceAnalytics,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: 'customer-dashboard',
      title: <FormattedMessage id="customer-dashboard" />,
      type: 'item',
      url: '/customer-dashboard',
      icon: icons.IconDeviceAnalytics,
      breadcrumbs: false,
    },
    {
      id: 'business-dashboard',
      title: <FormattedMessage id="business-dashboard" />,
      type: 'item',
      url: '/business-dashboard',
      icon: icons.IconDeviceAnalytics,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
