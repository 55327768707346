import PropTypes from 'prop-types';
import { ReactComponent as PersonIcon } from 'assets/images/auth/person.svg';

// material-ui

import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
// assets
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { shouldForwardProp } from '@mui/system';
import getAxiosInstance from 'api';
import InfoImg from 'assets/images/info.png';
import { ReactComponent as ApproveIcon } from 'assets/images/svg/approve.svg';
import { ReactComponent as CloseIcon } from 'assets/images/svg/close.svg';
import { ReactComponent as InfoIcon } from 'assets/images/svg/info.svg';
import { ReactComponent as RejectIcon } from 'assets/images/svg/reject.svg';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import MainCard from 'ui-component/cards/MainCard';
const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#F0F0F0',
  color: 'rgba(33, 33, 32, 0.60)',
  border: '1px solid #DEDEDE',
  '&:last-child': {
    width: '30px', // Adjust the width as needed
  },
}));
// table data
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

// table data
const colorChip = {
  Created: {
    color: '#FF8743',
    background: '#FFF3EC',
  },
  'In Progress': {
    color: '#FFDD14',
    background: '#FFFEE0',
  },
  Active: {
    color: '#1CB110',
    background: '#E8F7E7',
  },
};

// table filter
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// table header
const headCells = [
  {
    id: 'no',
    numeric: false,
    disablePadding: false,
    label: '#',
  },
  {
    id: 'appName',
    numeric: true,
    disablePadding: false,
    label: 'App Name',
  },
  {
    id: 'created',
    numeric: true,
    disablePadding: false,
    label: 'Created',
  },
  {
    id: 'Action',
    disablePadding: false,
    label: 'Action',
    style: { width: '25%' },
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '1px solid #DEDEDE',
  color: '#212120',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
}));

const ReviewButton = styled(Button)(({ theme }) => ({
  padding: '4px 12px',
  gap: '4px',
  borderRadius: '10px',
  background: '#FFF6F3',
  border: '1px solid #FC5719',
  color: theme.palette.secondary.main,

  ':hover': {
    background: '#FFF6F3',
    border: '1px solid #FC5719',
    color: theme.palette.secondary.main,
  },
}));
const ProductCard = styled(MainCard)(({ theme }) => ({
  border: '1px solid rgba(0, 24, 35, 0.1)',
  height: '100%',
  cursor: 'pointer',

  ':hover': {
    background: 'rgba(252, 87, 25, 0.05)',
    border: '1px solid rgba(252, 87, 25, 0.2)',
  },
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 350,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: '8px',
    border: '1px solid rgba(33, 33, 32, 0.10)',
    background: '#FFFFFF',

    '& input': {
      background: 'transparent !important',
      paddingLeft: '4px !important',
    },
    [theme.breakpoints.down('lg')]: {
      width: 250,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 4,
      background:
        theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff',
    },
  })
);

// ==============================|| TABLE - HEADER ||============================== //

function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCellHead
            style={{ width: headCell.style?.width }}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : undefined}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCellHead>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// ==============================|| TABLE - TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }) => (
  <Toolbar
    sx={{
      p: 0,
      pl: 1,
      pr: 1,
      ...(numSelected > 0 && {
        color: (theme) => theme.palette.secondary.main,
      }),
    }}
  >
    {numSelected > 0 ? (
      <Typography color="inherit" variant="subtitle1">
        {numSelected} selected
      </Typography>
    ) : (
      <Typography variant="h4" id="tableTitle">
        Nutrition
      </Typography>
    )}
    <Box sx={{ flexGrow: 1 }} />
    {numSelected > 0 && (
      <Tooltip title="Delete">
        <IconButton size="large">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    )}
  </Toolbar>
);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

// ==============================|| TABLE - ENHANCED ||============================== //

export default function ApproveBusinessApp() {
  const theme = useTheme();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [order, setOrder] = useState('ASC');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [limit, setLimit] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [apiList, setApiList] = useState([]);
  const [total, setTotal] = useState();
  const [isFilter, setIsFilter] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAppId, setSelectedAppId] = useState();
  const [isOpenIntegrationModel, setIsOpenIntegrationModel] = useState();

  const handleManageModel = () => {
    setIsOpen(!isOpen);
  };
  const fetchApiApproval = () => {
    const payload = {
      page: page + 1,
      limit,
      sortBy: orderBy,
      sortDirection: order,
      search: search,
      filter: {
        isActive: isFilter,
        reviewStatus: 'PENDING',
      },
    };
    const businessPartnerURL = 'list';
    getAxiosInstance(
      `adminOrchestrator/businessPartner/${businessPartnerURL}`,
      'post',
      payload
    ).then((res) => {
      if (res?.data?.codes === 200) {
        setApiList(res?.data?.data?.items);
        setTotal(res?.data?.data?.total);
      }
    });
  };

  useEffect(() => {
    fetchApiApproval();
  }, [page, limit, orderBy, order, search, isFilter]);

  const handleMenuClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpenIntegrationModel(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };
  const isSelected = () => {};

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
  };

  const handleApprove = () => {
    setIsOpenIntegrationModel(true);
  };
  const handleReject = () => {
    getAxiosInstance(
      `adminOrchestrator/businessPartner/reviewBusinessPartner`,
      'post',
      {
        id: selectedAppId,
        isApproved: false,
      }
    )
      .then((res) => {
        if (res?.data?.codes === 200) {
          toast.success('App reject successfully');
          handleManageModel();
          fetchApiApproval();
        }
      })
      .catch((err) => {
        toast.error('Failed to approve app');
      });
  };
  const handleClickApproval = (type) => {
    getAxiosInstance(
      `adminOrchestrator/businessPartner/reviewBusinessPartner`,
      'post',
      {
        id: selectedAppId,
        isApproved: true,
        integrationApproach: type, //Popup,API
      }
    )
      .then((res) => {
        if (res?.data?.codes === 200) {
          toast.success('App approved successfully');
          handleManageModel();
          fetchApiApproval();
          handleClose();
        }
      })
      .catch((err) => {
        toast.error('Failed to approve app');
      });
  };

  return (
    <Box mt={3}>
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="h3">Business Partner Approvals</Typography>
      </Stack>
      <Card sx={{ mt: 2 }}>
        <>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}

          {/* table */}
          <TableContainer>
            <Table
              sx={{
                minWidth: 750,
                backgroundColor: 'white',
              }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={total}
              />
              <TableBody>
                {apiList.length ? (
                  <>
                    {apiList.map((row, index) => {
                      if (typeof row === 'number') return null;
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell id={labelId}>
                            {page === 0
                              ? index + 1
                              : page * limit + (index + 1)}
                          </StyledTableCell>
                          <StyledTableCell>{row?.companyName}</StyledTableCell>
                          <StyledTableCell>
                            {moment(row?.createdAt).format('MMM DD, YYYY')}
                          </StyledTableCell>
                          <StyledTableCell>
                            {/* <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={1}
                            >
                              {}
                            </Stack> */}
                            <ReviewButton
                              variant="contained"
                              size="small"
                              fullWidth
                              onClick={() => {
                                setSelectedAppId(row?.id);
                                handleManageModel();
                              }}
                              endIcon={
                                <Box
                                  component={'img'}
                                  src={InfoImg}
                                  sx={{ height: 24 }}
                                />
                              }
                            >
                              Review
                            </ReviewButton>
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* table pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </Card>
      <Dialog
        open={isOpen}
        onClose={() => handleClose}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '500px', // Set your width here
            },
          },
        }}
      >
        <IconButton
          sx={{ position: 'absolute', right: 5, top: 5 }}
          onClick={handleManageModel}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Stack
            gap={3}
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
          >
            <InfoIcon />
            <Stack gap={1}>
              <Typography variant="h1"> Confirm Request</Typography>
              <Typography variant="h4" fontWeight={400}>
                How would you like to proceed with this request?{' '}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'flex-end'}
            gap={1.5}
            mt={3}
          >
            <Button
              variant="contained"
              startIcon={<RejectIcon />}
              color="error"
              onClick={handleReject}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              startIcon={<ApproveIcon />}
              color="success"
              onClick={handleApprove}
            >
              Approve
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isOpenIntegrationModel}
        onClose={() => handleClose}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '500px', // Set your width here
            },
          },
        }}
      >
        <IconButton
          sx={{ position: 'absolute', right: 5, top: 5 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack gap={3}>
            <Typography variant="h1" textAlign={'center'}>
              {' '}
              Integration Approach
            </Typography>
            <Grid container spacing={3} justifyContent={'center'}>
              <Grid item xs={12} md={6}>
                <ProductCard
                  onClick={() => {
                    handleClickApproval('Popup');
                  }}
                >
                  <PersonIcon />

                  <Stack gap={1} mt={5}>
                    <Typography variant="h2">Popup</Typography>
                  </Stack>
                </ProductCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ProductCard
                  onClick={() => {
                    handleClickApproval('API');
                  }}
                >
                  <PersonIcon />

                  <Stack gap={1} mt={5}>
                    <Typography variant="h2">API</Typography>
                  </Stack>
                </ProductCard>
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
