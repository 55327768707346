import PropTypes from 'prop-types';

// material-ui
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
// assets
import DeleteIcon from '@mui/icons-material/Delete';
import { shouldForwardProp } from '@mui/system';
// import getAxiosInstance from 'api';
import { useEffect, useState } from 'react';
import moment from 'moment';

// table data
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

// table data
const colorChip = {
  Created: {
    color: '#FF8743',
    background: '#FFF3EC',
  },
  'In Progress': {
    color: '#FFDD14',
    background: '#FFFEE0',
  },
  Active: {
    color: '#1CB110',
    background: '#E8F7E7',
  },
};

// table filter
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// table header
const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: '',
    width: '0.5px',
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Created Date',
  },
  {
    id:'merchantName',
    numeric: true,
    disablePadding: false,
    label: 'Merchant Name',
  },
  {
    id: 'reference',
    numeric: true,
    disablePadding: false,
    label: 'Transaction Reference',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: '',
    numeric: true,
    disablePadding: false,
    width: '0.5px',
    label: '',
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '1px solid #DEDEDE',
  color: '#212120',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 350,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: '8px',
    border: '1px solid rgba(33, 33, 32, 0.10)',
    background: '#FFFFFF',

    '& input': {
      background: 'transparent !important',
      paddingLeft: '4px !important',
    },
    [theme.breakpoints.down('lg')]: {
      width: 250,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 4,
      background:
        theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff',
    },
  })
);

// ==============================|| TABLE - HEADER ||============================== //

function EnhancedTableHead({
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
    backgroundColor: '#F0F0F0',
    color: 'rgba(33, 33, 32, 0.60)',
    border: '1px solid #DEDEDE',
  }));

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCellHead
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : undefined}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCellHead>
        ))}
      </TableRow>
    </TableHead>
  );
}


EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['ASC', 'DESC']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// ==============================|| TABLE - TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }) => (
  <Toolbar
    sx={{
      p: 0,
      pl: 1,
      pr: 1,
      ...(numSelected > 0 && {
        color: (theme) => theme.palette.secondary.main,
      }),
    }}
  >
    {numSelected > 0 ? (
      <Typography color="inherit" variant="subtitle1">
        {numSelected} selected
      </Typography>
    ) : (
      <Typography variant="h4" id="tableTitle">
        Nutrition
      </Typography>
    )}
    <Box sx={{ flexGrow: 1 }} />
    {numSelected > 0 && (
      <Tooltip title="Delete">
        <IconButton size="large">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    )}
  </Toolbar>
);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

// ==============================|| TABLE - ENHANCED ||============================== //

export default function TransactionPayout({ appName }) {
  const theme = useTheme();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [order, setOrder] = useState('DESC');
  const [orderBy, setOrderBy] = useState('id');
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [limit, setLimit] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [apiList, setApiList] = useState([]);
  const [total, setTotal] = useState();
  const [isFilter, setIsFilter] = useState(true);
  const [transactionsList, setTransactionsList] = useState([]);

  useEffect(() => {
    const payload = {
      page: page + 1,
      limit,
      sortBy: orderBy,
      sortDirection: order,
      search: search,
      filter: {
        isActive: isFilter,
      },
    };
    const consentURL = appName ? `${appName}/consent/list` : 'consent/list';

    // getPayouts(payload)
    //   .then((res) => {
    //     if (res.codes == 200) {
    //       setTransactionsList(res?.data?.items || []);
    //       console.log(res?.data?.total);
          
    //       setTotal(res?.data?.total);
    //       setLoading(false);
    //       // setTotalSize(parseInt(res?.data.total ? res?.data.total : 10));
    //     } else {
    //       setLoading(false);
    //     }
    //   })
    //   .catch();
  }, [page, limit, orderBy, order, search, isFilter, appName]);

  const handleMenuClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };
  const isSelected = () => {};

  const handleClick = (event, name) => {
    // const selectedIndex = selected.indexOf(name);
    // let newSelected = [];

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, name);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    // }
    // setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
  };

  return (
    <Box mt={3}>
      <Card sx={{ mt: 2 }}>
        <>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}

          {/* table */}
          <TableContainer>
            <Table
              sx={{
                minWidth: 750,
                backgroundColor: 'white',
              }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={total}
              />
              <TableBody>
                {transactionsList.length ? (
                  <>
                    {transactionsList.map((row, index) => {
                      if (typeof row === 'number') return null;
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell id={labelId}>
                            {moment(row?.createdOn).format(
                              'MMM DD, YYYY hh:mm A'
                            )}
                          </StyledTableCell>
                          <StyledTableCell>{row?.refrence}</StyledTableCell>
                          <StyledTableCell>{row?.amount}</StyledTableCell>
                          <StyledTableCell sx={{
                              width: '5px',
                              textAlign: 'center',
                            }}>
                            <MoreVertIcon />
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* table pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </Card>
    </Box>
  );
}
