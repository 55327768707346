// assets
import Flag1 from 'assets/images/widget/AUSTRALIA.jpg';
import Flag2 from 'assets/images/widget/BRAZIL.jpg';
import Flag3 from 'assets/images/widget/GERMANY.jpg';
import Flag4 from 'assets/images/widget/UK.jpg';
import Flag5 from 'assets/images/widget/USA.jpg';

import PropTypes from 'prop-types';
import * as React from 'react';

// material-ui
import {
  Button,
  CardContent,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party

// project imports
import { getProducts } from 'store/slices/product';
import MainCard from 'ui-component/cards/MainCard';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import SearchIcon from '@mui/icons-material/Search';
import getAxiosInstance from 'api';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ChoosePlan from 'views/dashboard/Business/ChoosePlan';

// const prodImage = require.context('assets/images/e-commerce', true);

// table sort
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: 'id',
    numeric: true,
    label: 'ID',
    align: 'center',
  },
  {
    id: 'companyName',
    numeric: false,
    label: 'Business Partner Name',
    align: 'left',
  },
  {
    id: 'emailAddress',
    numeric: false,
    label: 'Primary User Email',
    align: 'left',
  },
  {
    id: 'countUser',
    numeric: false,
    label: 'No of Secondary User',
    align: 'left',
  },
];

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }) => (
  <Toolbar
    sx={{
      p: 0,
      pl: 2,
      pr: 1,
      color: numSelected > 0 ? 'secondary.main' : 'inherit',
    }}
  >
    {numSelected > 0 ? (
      <Typography
        sx={{ flex: '1 1 100%' }}
        color="inherit"
        variant="h4"
        component="div"
      >
        {numSelected} Selected
      </Typography>
    ) : (
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Nutrition
      </Typography>
    )}

    {numSelected > 0 && (
      <Tooltip title="Delete">
        <IconButton size="large">
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    )}
  </Toolbar>
);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

// =========================|| DASHBOARD ANALYTICS - LATEST CUSTOMERS TABLE CARD ||========================= //

const TableCard = ({
  rowsData,
  page,
  setPage,
  limit,
  setLimit,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  search,
  setSearch,
  total,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState();
  const [rows, setRows] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setRows(rowsData);
  }, [rowsData]);

  const handleSearch = (event) => {
    const newString = event?.target.value;
    setSearch(newString || '');

    if (newString) {
      const newRows = rows?.filter((row) => {
        let matches = true;

        const properties = headCells;
        let containsQuery = false;

        properties.forEach((property) => {
          if (
            row[property] &&
            row[property]
              .toString()
              .toLowerCase()
              .includes(newString.toString().toLowerCase())
          ) {
            containsQuery = true;
          }
        });

        if (!containsQuery) {
          matches = false;
        }
        return matches;
      });
      setRows(newRows);
    } else {
      getProducts();
    }
  };

  const handleRequestSort = (headCell) => {
    const isAsc = orderBy === headCell && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(headCell);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event?.target.value, 10));
    setPage(0);
  };
  const handleActive = () => {
    getAxiosInstance(
      `adminOrchestrator/user/updateActiveStatus/${selected}`,
      'post',
      {}
    ).then((res) => {
      if (res?.data?.data) {
        toast.success(
          `User ${
            res?.data?.data?.isActive ? 'Activate' : 'Deactivete'
          } successfully.`
        );
      }
    });
  };
  const handleUnlockMail = (row) => {
    getAxiosInstance(
      `adminOrchestrator/user/unlockEmail/${row?.id}`,
      'get',
      {}
    ).then((res) => {
      if (res?.data?.data?.success) {
        toast.success(`Unlock Email send successfully.`);
      }
    });
  };
  const handleDeleteUser = (row) => {
    getAxiosInstance(`adminOrchestrator/user/${row?.id}`, 'delete').then(
      (res) => {
        if (res?.data?.data?.isDeleted) {
          toast.success(`Delete user successfully.`);
        }
      }
    );
  };
  const handleCloseModel = () => {
    setIsOpen(false);
    // setSelected();
  };
  const handleAction = (row) => {
    setIsOpen(true);
  };
  const handlerRedirect = () => {
    navigate('/api-business-partner/app-approve');
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * limit - rows.length) : 0;
  return (
    <>
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
        alignItems={'center'}
        my={3}
      >
        <Typography variant="h3">Api Business Partner</Typography>
        <Button
          color="secondary"
          sx={{ width: 'fit-content' }}
          // selected={selectedIndex === 4}
          variant="contained"
          onClick={handlerRedirect}
        >
          Approvals
        </Button>
      </Stack>
      <MainCard content={false}>
        <CardContent>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            placeholder="Search Product"
            value={search}
            size="small"
          />
        </CardContent>

        {/* table */}
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={() => handleRequestSort(headCell.id)}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Typography component="span" sx={{ display: 'none' }}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Typography>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell sortDirection={false} align="center">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color:
                        theme.palette.mode === 'dark'
                          ? theme.palette.grey[600]
                          : 'grey.900',
                    }}
                  >
                    Action
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  if (typeof row === 'number') return null;
                  // const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      // selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        // onClick={(event) => handleClick(event, row.name)}
                        sx={{ cursor: 'pointer' }}
                      >
                        {row?.id}
                        {/* <Avatar src={row.image && prodImage(`./${row.image}`).default} size="md" variant="rounded" /> */}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{ cursor: 'pointer' }}
                      >
                        {row?.companyName ? row?.companyName : '--'}
                      </TableCell>
                      {/* <TableCell>{format(new Date(row?.created), 'E, MMM d yyyy')}</TableCell> */}
                      <TableCell>{row?.emailAddress}</TableCell>
                      <TableCell align="center">
                        {/* {row?.createdAt
                          ? moment(row?.createdAt).format('ddd, MMM YYYY')
                          : '--'} */}
                      </TableCell>
                      {/* <TableCell align="center">
                        <Chip
                          size="small"
                          label={row.isAcceptedTnc ? 'Yes' : 'No'}
                          chipcolor={row.isAcceptedTnc ? 'success' : 'error'}
                          sx={{
                            borderRadius: '4px',
                            textTransform: 'capitalize',
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Chip
                          size="small"
                          label={row.isActive ? 'Active' : 'Deactive'}
                          chipcolor={row.isActive ? 'success' : 'error'}
                          sx={{
                            borderRadius: '4px',
                            textTransform: 'capitalize',
                          }}
                        />
                      </TableCell> */}

                      {/* <TableCell align="center">
                        {row?.pricingPlan ? row?.pricingPlan : '--'}
                      </TableCell>
                      <TableCell align="center">
                        {row?.status ? row?.status : '--'}
                      </TableCell> */}
                      <TableCell align="center" sx={{ pr: 3 }}>
                        <IconButton
                          onClick={(e) => {
                            handleMenuClick(e);
                            setSelected(row?.id);
                          }}
                          size="large"
                        >
                          <MoreHorizOutlinedIcon
                            fontSize="small"
                            aria-controls="menu-popular-card-1"
                            aria-haspopup="true"
                            sx={{ color: 'grey.500' }}
                          />
                        </IconButton>
                        <Menu
                          id="menu-popular-card-1"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          variant="selectedMenu"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          sx={{
                            '& .MuiMenu-paper': {
                              boxShadow: theme.customShadows.z1,
                            },
                          }}
                        >
                          <MenuItem onClick={handleActive}> Activate</MenuItem>
                          <MenuItem> Manage Secondary user</MenuItem>
                          <MenuItem onClick={() => handleAction(row)}>
                            Choose Plan
                          </MenuItem>
                          <MenuItem> Delete </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>

        {/* table pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </MainCard>
      <ChoosePlan
        isOpen={isOpen}
        handleClose={handleCloseModel}
        selected={selected}
      />
    </>
  );
};

TableCard.propTypes = {
  title: PropTypes.string,
};

export default TableCard;
